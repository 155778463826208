<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
         <!-- <el-form-item label="排序">
          <el-select
            v-model="queryForm.sort"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in sortList"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item> --> 
        <el-form-item label="手机">
          <el-input
            placeholder="请输入手机号码"
            v-model="queryForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入内容" v-model="queryForm.title">
            <el-select v-model="queryForm.title_type" slot="prepend" placeholder="请选择">
              <el-option label="帖子内容" value="name"></el-option>
              <el-option label="帖子ID" value="id"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item label="是否精华">
          <el-select
            v-model="queryForm.is_essence"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in essence"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="状态">
          <el-select
            v-model="queryForm.check"
            placeholder="请选择"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in isDisable"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toDisable"
            >批量禁用</el-button
          >
        </el-form-item>
        <el-form-item class="generate">
          <el-button type="primary" @click="toAble"
            >批量启用</el-button
          >
        </el-form-item>
      </el-form>

      <el-table
        :data="topicList"
        stripe
        class="table"
        ref="multipleSelection"
        header-cell-class-name="table-header"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="ID" prop="id" align="center"></el-table-column>
        <el-table-column label="标题" prop="msg" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column label="发帖人昵称" prop="user_info.nickname" align="center"></el-table-column>
        <el-table-column label="发帖人手机" prop="user_info.phone" align="center"></el-table-column>
        <el-table-column label="发帖时间" align="center">
          <template slot-scope="scope">
             {{scope.row.create_time}}
          </template>
        </el-table-column>
        <el-table-column label="回复数" prop="message_num" align="center"></el-table-column>
        <el-table-column label="点赞数" prop="like" align="center"></el-table-column>
        <el-table-column label="被举报数" prop="report_count" align="center"></el-table-column>
        <el-table-column label="是否精选" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.is_essence == 1">
              已精选
            </el-tag>
            <el-tag type="danger" v-else
              >未精选
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="getTopicDetail(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-star-on"
              circle
              @click="setTopicEssence(scope.row)"
              size="small"
            ></el-button>
            <!-- <el-button
              type="warning"
              icon="el-icon-star-off"
              circle
              @click="setTopicEssence(scope.row.id)"
              size="small"
              v-else-if="is_essence_title == '未精选'"
            ></el-button> -->
            <!-- <el-button
              type="danger"
              icon="el-icon-minus"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button> -->
            <el-tag type="warning" v-if="queryForm.check == 1" @click="removeTask(scope.row.id)" style="margin-left:10px">
              禁用
            </el-tag>
            <el-tag type="warning" v-else-if="queryForm.check == -1" @click="removeTask(scope.row.id)" style="margin-left:10px">
              启用
            </el-tag>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

      <!-- 帖子详情 -->
      <el-dialog :title="addtitle" :visible.sync="topicDialog" :center=true width="50%" @close="closed" :close-on-click-modal=false>
        <el-form :model="topicDetail"  ref="addForm">
          <el-form-item el-form-item label="是否精选：" label-width="100px">
            {{topicDetail.is_essence_title}}
          </el-form-item>
          <el-form-item el-form-item label="发帖人：" label-width="100px">
            {{topicDetail.nickname}}
          </el-form-item>
          <el-form-item el-form-item label="发帖时间：" label-width="100px">
            {{topicDetail.create_time}}
          </el-form-item>
          <el-form-item el-form-item label="发帖内容：" label-width="100px">
            {{topicDetail.msg}}
            <div style="display:flex;align-items:center;">
              <img :src="topicDetail.img1" alt="" v-if="topicDetail.img1" style="width: 44%;margin-right:10px;">
              <img :src="topicDetail.img2" alt="" v-if="topicDetail.img2" style="width: 44%;">
            </div>
            <div style="display:flex;align-items:center;margin-top:10px">
              <img :src="topicDetail.img3" alt="" v-if="topicDetail.img3" style="width: 44%;margin-right:10px;">
              <img :src="topicDetail.img4" alt="" v-if="topicDetail.img4" style="width: 44%;">
            </div>
          </el-form-item>
          <el-form-item el-form-item label="评论数：" label-width="100px">
            {{topicDetail.message_num}}
          </el-form-item>
          <el-form-item el-form-item label="点赞数：" label-width="100px">
            {{topicDetail.like}}
          </el-form-item>
          <!-- <el-form-item el-form-item label="举报内容：" label-width="100px">
            {{}}
          </el-form-item> -->
        </el-form>
      </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");
import {
  topicList,
  topicDetail,
  delTopic,
  topicEssence
} from "@/api/French/post_management.js";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        // sort: 2,
        check: 1,
        // is_essence: null,
        phone: "",
        title: "",
        title_type: "name",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      syntaxType: [],
      topicList: [],
      topicDetail: {},
      // sortList: [{key:1,value:"正序"},{key:2,value:"倒序"}],
      essence: [{key:1,value:"是"},{key:0,value:"否"}],
      isDisable: [{key:-1,value:"禁用"},{key:1,value:"启用"}],
      total: 0,
      topicDialog: false,
      times: null,
      addtitle: "",
      isAdd: "add",
      lineData: [],
      isCascader: true,
      multipleSelection: [],
    };
  },

  created() {
    this.getTopicList({check:1});
  },
  methods: {
    //获取多选数组
    handleSelectionChange(val) {
      let checkArr = []
      val.forEach((item)=>{
        checkArr.push(item.id)
      })
      this.multipleSelection = checkArr;
    },
    //获取帖子列表
    getTopicList(item){
      topicList(item).then((res)=>{
        // console.log(res)
        this.total = res.data.total;
        // if(item.sort == 1){
        //   this.topicList = res.data.data.reverse()
        // }else{
        //   this.topicList = res.data.data
        // }
        this.topicList = res.data.data
        this.topicList.forEach((item,index)=>{
          if(item.is_essence == 1){
            item.is_essence_title = "已精选"
          }else if(item.is_essence == 0){
            item.is_essence_title = "未精选"
          }
        })
      })
    },

    //设置精华
    setTopicEssence(item){
      console.log(item)
      let data = {}
      if(item.is_essence == 0){
        data = {id:[item.id],status:1}
      }else if(item.is_essence == 1){
        data = {id:[item.id],status:0}
      }
      topicEssence(data).then((res)=>{
        console.log(res)
        this.getTopicList(this.queryForm);
      })
    },

    // 禁用帖子
    async removeTask(id) {
      let data = {}
      if(this.queryForm.check == 1){
        data.id = [id],
        data.status = -1
        const result = await this.$confirm("是否要禁用该帖子?", "禁用提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => err);
        if (result !== "confirm") return this.$message.info("已经取消禁用");
        delTopic(data).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("禁用帖子成功");
          this.getTopicList(this.queryForm);
        });
      }else{
        data.id = [id],
        data.status = 1
        delTopic(data).then((res) => {
          if (res.code !== 1) {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("启用帖子成功");
          this.getTopicList(this.queryForm);
        });
      }
    },

    //批量禁用
    async toDisable(){
      const result = await this.$confirm("是否要禁用这些帖子?", "禁用提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消禁用");
      delTopic({id:this.multipleSelection,status:-1}).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("禁用帖子成功");
        this.getTopicList(this.queryForm);
      });
    },

    //批量启用
    async toAble(){
      const result = await this.$confirm("是否要启用这些帖子?", "启用提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消启用");
      delTopic({id:this.multipleSelection,status:1}).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("启用帖子成功");
        this.getTopicList(this.queryForm);
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "帖子详情";
      this.lineData = [];
      this.addDialog = true;
      this.isCascader = true;
    },

    // 点击编辑图标
    getTopicDetail(item) {
      this.addtitle = "帖子详情";
      this.topicDialog = true;
      topicDetail({id:item.id}).then((res)=>{
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.topicDetail = res.data.data;
        if(this.topicDetail.is_essence == 1){
          this.topicDetail.is_essence_title = "已精选"
        }else{
          this.topicDetail.is_essence_title = "未精选"
        }
        this.topicDetail.nickname = item.user_info.nickname;
      })
    },

    // 弹窗关闭事件
    closed() {
      this.topicDetail = {};
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      console.log(this.queryForm)
      this.getTopicList(this.queryForm);
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getTopicList(this.queryForm);
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getTopicList(this.queryForm);
    },
  },
};
</script>

<style>
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.el-select .el-input {
  width: 100px;
}
</style>
