import request from "@/utils/request";

// 发帖列表
export function topicList(params) {
  return request({
      url: '/admin/french/topicList',
      method: 'get',
      params
  })
}

//发帖详情
export function topicDetail(params) {
  return request({
      url: '/admin/french/topicDetail',
      method: 'get',
      params
  })
}

// 启用禁用发帖
export function delTopic(data) {
  return request({
      url: '/admin/french/delTopic',
      method: 'delete',
      data
  })
}

// 设置精选
export function topicEssence(data) {
  return request({
      url: '/admin/french/topicEssence',
      method: 'post',
      data
  })
}

